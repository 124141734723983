import { DEFAULT_EXPIRE_DAYS, DEVICE_ID_COOKIE, SITE_LOCATION_COOKIE_NAME, USER_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { IncomingMessage, ServerResponse } from 'http';
import { getObjectFromRequestCookie, getStringFromRequestCookie, getResponseCookieValue } from './cookies';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import { CountryCode, CountryCodeList } from 'types/countries';
import isUserAgentCrawler, { isUserAgentLighthouse } from './crawler-agent';

import configJSON from 'config.json';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import endpoint from './endpoint';
import cookie from 'cookie';
import { getAddressesByParams } from 'data/addresses';
import { provinceOrStateCodeFromName } from './province_or_state';

export interface IUserLocation {
  name: string;
  slug: string;
  latitude: number;
  longitude: number;
  countryCode: CountryCode;
  ip?: string;
}
export interface GeolocationData {
  country_code: string;
  city: string;
  region: string;
  region_code: string;
  latitude: number;
  longitude: number;
  ip: string;
};

/**
 * Retrieves the user location based on the incoming request and IP address.
 *
 * The function determines the user location through the following steps:
 * 1. Attempts to get the location from the user location cookie to overwrite the default.
 * 2. If not previously stored, use getUserLocation to fetch location by IP address.
 * 3. Otherwise, assign a fallback value depending on siteLocation (country switcher).
 *
 * The function applies specific rules based on the detected conditions:
 * - If feature overrides indicate using the US location, sets the user location to the default US city payload.
 * - If the user location country code does not match the site location, returns the default city payload based on the site location.
 *
 * If no user location is determined through these steps, defaults to the city payload based on the site location.
 *
 * @param request The incoming HTTP request object.
 * @returns The user location payload.
 */
export async function getUserLocationFromRequest(request?: IncomingMessage, res?: ServerResponse) {
  const isCrawler = isUserAgentCrawler(request.headers['user-agent']);
  const isCrawlerLighthouse = isUserAgentLighthouse(request.headers['user-agent']);
  let isSiteUs = false;
  let userLocation: IUserLocation | undefined;

  // Check if the code is running locally (assuming NODE_ENV or a specific IP pattern like localhost)
  const isLocal = process.env.NODE_ENV === 'development';
  const isVercel = process.env.VERCEL_ENV === 'production';

  if (request) {
    // Get the site location from the cookie
    const siteLocation = getStringFromRequestCookie(SITE_LOCATION_COOKIE_NAME, request) as CountryCode;
    if (siteLocation === CountryCodeList.UNITED_STATES) isSiteUs = true;

    // Set default userLocation
    userLocation = isSiteUs ? defaultUSCityPayload : defaultCACityPayload;

    // If it test environment, return the default user location
    if (process.env.TEST_ENVIRONMENT) {
      if (res) setResponseHeader(res, userLocation, isLocal);
      return userLocation;
    }

    // Check for stored user location in the cookies
    let storedUserLocation = getObjectFromRequestCookie<IUserLocation>(USER_LOCATION_COOKIE_NAME, request);

    // Check if we attached cookie already to the response
    const locationFromResponse = getResponseCookieValue(res, USER_LOCATION_COOKIE_NAME);
    if (locationFromResponse) {
      storedUserLocation = locationFromResponse;
    }

    if (storedUserLocation) {
      userLocation = storedUserLocation;
    } else {
      // If we are running on staging or production, fetch the user location from cloudflare headers
      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        const data = getCloudFlareLocation(request);

        try {
          userLocation = await parseGeolocationData(data);
          // Set the cookie in the response
          if (res) setResponseHeader(res, userLocation, isLocal);
          return userLocation;
        } catch (error: any) {
          const errorLog = JSON.stringify(['### Failed to parse user location from cloudflare', error.message || error]);
          console.error(errorLog);
        }
      }

      // If we got here means that we are not in production or staging or we failed to parse the cloudflare data
      const clientIp = isVercel ? request.headers['x-forwarded-for'] : request.headers['cf-connecting-ip'];
      let deviceId = getStringFromRequestCookie(DEVICE_ID_COOKIE, request);
      if (!deviceId) deviceId = request.headers['cf-ray'] as string || 'unknown';

      // Call getUserLocation with or without specific IP based on environment
      try {
        if (isLocal) {
          userLocation = await fetchUserLocation(deviceId); // Without specific IP
        } else if (clientIp && !isCrawler && !isCrawlerLighthouse) {
          userLocation = await fetchUserLocation(deviceId, clientIp as string | undefined);
        }
      } catch (error: any) {
        const errorLog = JSON.stringify(['### Failed to fetch user location:', error.message || error]);
        console.error(errorLog);
        // TODO: Notify user there was an issue fetching their location. Default location has been set.
      }
    }
  }

  // Set the cookie in the response
  if (res) setResponseHeader(res, userLocation, isLocal);

  // Return user location or fallback based on the site location
  return userLocation || (isSiteUs ? defaultUSCityPayload : defaultCACityPayload);
}


const setResponseHeader = (res: ServerResponse, payload: IUserLocation, isLocal: boolean) => {
  res.setHeader('Set-Cookie', cookie.serialize(USER_LOCATION_COOKIE_NAME, JSON.stringify(payload), {
    httpOnly: false,
    secure: !isLocal,
    maxAge: DEFAULT_EXPIRE_DAYS * 24 * 60 * 60,
  }));
  return payload;
};

const fetchUserLocation = async (deviceId: string, ip?: string) => {
  const host = configJSON.host;
  return endpoint<Record<string, any>>(`${host}/api/ip-location?device_id=${deviceId}&user_ip=${ip}`)
    .then(response => {
      if (response.error) {
        throw new Error(response.error);
      } else {
        const data = response.data;
        return data;
      }
    });
};

const getCloudFlareLocation = (request: any): GeolocationData => {
  const cf = request.headers['cf-ipcountry'];
  const city = request.headers['cf-ipcity'];
  const region = request.headers['cf-region'];
  let regionCode = request.headers['cf-region-code'];
  const latitude = request.headers['cf-iplatitude'];
  const longitude = request.headers['cf-iplongitude'];
  const ip = request.headers['cf-connecting-ip'];

  // Sometimes the region code is not provided, so we try to get it from the region name
  if (!regionCode && region) {
    regionCode = provinceOrStateCodeFromName(region);
  }

  return {
    country_code: cf,
    city,
    region,
    region_code: regionCode,
    latitude,
    longitude,
    ip,
  };
};

const parseGeolocationData = async (data: GeolocationData): Promise<IUserLocation> => {
  const { country_code, city, region_code, latitude, longitude, ip } = data;

  if (!country_code || !city || !region_code || !latitude || !longitude) {
    const missingFields = [];

    if (!country_code) missingFields.push('country_code');
    if (!city) missingFields.push('city');
    if (!region_code) missingFields.push('region_code');
    if (!latitude) missingFields.push('latitude');
    if (!longitude) missingFields.push('longitude');

    if (missingFields.length > 0) {
      throw new Error(`Incomplete user location data received. Missing properties: ${missingFields.join(', ')}, IP: ${ip}`);
    }
  }

  let payload: IUserLocation;
  if (country_code !== CountryCodeList.CANADA) { // Non-Canadian locations
    payload = {
      name: `${city}, ${region_code}`,
      slug: `${dasherize(city.toLowerCase())}-${region_code.toLowerCase()}`,
      latitude,
      longitude,
      countryCode: CountryCodeList.UNITED_STATES,
      ip: ip,
    };
  } else { // Canadian locations
    const [address] = await getAddressesByParams({
      filter: {
        type: 'sub-division',
        position: JSON.stringify({
          type: 'Point',
          coordinates: [longitude, latitude],
        }),
      },
    });

    if (address && address.subDivision) {
      payload = {
        name: `${address.subDivision}, ${address.province}`,
        slug: address.slug,
        latitude,
        longitude,
        countryCode: country_code,
        ip: ip,
      };
    } else {
      throw new Error('Address subDivision not found for Canadian location.');
    }
  }

  return payload;
};
