import { ThemeNames } from 'types/themes';

export const DEFAULT_EXPIRE_DAYS = 7;
export const LOCATION_EXPIRE_DAYS = 28;
export const USER_EXPIRE_DAYS = 183;
export const ACCEPTED_TERMS_EXPIRE_DAYS = 365;

export const ACCEPTED_TERMS_COOKIE_NAME = 'accepted-terms';

export const ZOOCASA_TERMS_READ_COOKIE = 'zoocasa-terms-read';

export const ACTIVECAMPAIGN_SCRIPT = 'activecampaign-script';
export const GTM_SCRIPT = 'googletagmanager-script';
export const NEWRELIC_SCRIPT = 'newrelic-script';
export const GOOGLEMAPS_SCRIPT = 'googlemaps-script';

export const POPULAR_CITIES_COOKIE = 'user-popular-cities';
export const DEVICE_ID_COOKIE = 'device-id';

const RECENT_SEARCHES = 'recent-searches';
const LAST_SEARCH_LOCATION = 'last-search-location';

export const NAV_BUTTON_COLLAPSED = 'nav-button-collapsed';
export const HIDE_SAVE_HOME_COOKIE = 'hide-save-home';

export const FIRST_VISIT_TIMESTAMP_KEY = 'first-visit-timestamp';
export const HIDE_APP_BANNER = 'hide-app-banner';

export const SEARCH_PAGE_MOBILE_SLIDE_UP_LISTING_DATA_COOKIE = 'search-page-mobile-slide-up-listing-data-cookie';
export const LIGHTBOX_ROTATE_DISCLAIMER = 'lightbox-rotate-disclaimer';

export const QUEBEC_POPUP = 'quebec-popup';
export const HOME_APPRAISAL_PROPERTY = 'home-appraisal-property';
export const HOME_APPRAISAL_DETAILS = 'home-appraisal-details';

export const getLastSearchLocationTag = (theme: ThemeNames): string=> {
  return LAST_SEARCH_LOCATION + '-' + theme;
};

export const getRecentSearchesTag = (theme: ThemeNames): string => {
  return RECENT_SEARCHES + '-' + theme;
};

export const USER_LOCATION_COOKIE_NAME = 'user-location';
export const SITE_LOCATION_COOKIE_NAME = 'site-location';
export const SCREEN_COOKIE_NAME = 'screen';
export const USER_COOKIE_NAME = 'user';
export const LOC_PICKER_COOKIE_NAME = 'loc-picker';
export const CONNECTION_REQUEST_DISCLAIMERS_COOKIE_NAME = 'connection-request-disclaimers';
export const NOTICE_GDPR_PREFS_COOKIE_NAME = 'notice_gdpr_prefs';
export const LISTING_PARAMS_COOKIE_NAME = 'listing-params';
export const SCHOOL_PARAMS_COOKIE_NAME = 'school-params';

export const HIDE_NOTIFICATION_BANNER_COOKIE_NAME = 'hide-notification-banner';
export const COOKIE_BANNER_Z_INDEX = 9999;